import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { FaChevronRight } from 'react-icons/fa'

import TopImage from './TopImage'

const Servizio = ({ servizio }) => {
  return (
    <div>
      {!!servizio.imgUrl && <TopImage imgUrl={servizio.imgUrl} />}

      <div className="flex flex-row flex-wrap items-start justify-between py-12 px-12">
        <div className="pr-12 w-full lg:w-2/3">
          <style>{`
            .vantaggio {
              margin-left: 20px;
              margin-bottom: 15px;
            }
            .vantaggio:before {
              content: '';
              display: inline-block;
              background-color: #f7c611;
              margin: 0 10px 1px -20px;
              width: 7px;
              height: 7px;
            }
          `}</style>

          {servizio.nome && (
            <h2 className="mb-4 text-3xl leading-tight text-gls-blue-900">
              {servizio.nome}
            </h2>
          )}

          {servizio.paragrafi &&
            servizio.paragrafi.length > 0 &&
            servizio.paragrafi.map((p) => {
              return (
                <p key={p.id} className="mb-6">
                  {p.testo}
                </p>
              )
            })}

          {servizio.pesoDimensioni && servizio.pesoDimensioni.length > 0 && (
            <table className="w-full my-12">
              <thead>
                <tr>
                  <th
                    colSpan={2}
                    className="py-2 px-6 text-left bg-gls-blue-900 text-white rounded-t-lg"
                  >
                    Peso e dimensioni
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {servizio.pesoDimensioni.map((p) => {
                  return (
                    <tr key={p.id}>
                      <td className="py-3 px-6 text-left bg-gray-100 border-b border-gray-300">
                        {p.descrizione}
                      </td>
                      <td className="py-3 px-6 text-left bg-gray-100 border-b border-gray-300">
                        {p.valore.split('\n').map((v, i) => {
                          return <div key={i}>{v}</div>
                        })}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}

          {!!servizio.compatibileCon && servizio.compatibileCon.length > 0 && (
            <Fragment>
              <h3 className="mt-12 mb-2 text-md font-semibold text-gls-blue-900">
                Compatibile con
              </h3>
              <ul>
                {servizio.compatibileCon.map((c) => {
                  return (
                    <li key={c.id}>
                      <Link
                        to={c.linkUrl}
                        className="block mb-2 leading-snug text-sm text-gls-blue-600 hover:text-gls-blue-700"
                      >
                        <FaChevronRight className="inline-block mr-1 text-gls-yellow" />
                        {c.nome}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </Fragment>
          )}
        </div>

        <div className="w-full lg:w-1/3">
          {servizio.vantaggi && servizio.vantaggi.length > 0 && (
            <Fragment>
              <h3 className="mb-4 text-2xl text-gls-blue-900">Vantaggi</h3>
              <ul>
                {servizio.vantaggi.map((v) => {
                  return (
                    <li
                      key={v.id}
                      className="vantaggio mb-2 text-sm leading-snug text-gray-600"
                    >
                      {v.testo}
                    </li>
                  )
                })}
              </ul>
            </Fragment>
          )}

          {servizio.servizi && servizio.servizi.length > 0 && (
            <Fragment>
              <h3 className="mb-4 text-2xl text-gls-blue-900">Servizi</h3>
              <ul>
                {servizio.servizi.map((s) => {
                  return (
                    <li
                      key={s.id}
                      className="vantaggio mb-2 text-sm leading-snug"
                    >
                      <b className="text-gray-700">{s.testo1}</b>
                      <br />
                      <span className="text-gray-600">{s.testo2}</span>
                    </li>
                  )
                })}
              </ul>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default Servizio
