import React from 'react'

const TopImage = ({ imgUrl }) => {
  return (
    <div
      className="lg:rounded-t-lg w-full h-64"
      style={{
        background: `url(${imgUrl}) no-repeat center`,
        backgroundSize: 'cover',
      }}
    />
  )
}

export default TopImage
